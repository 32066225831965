footer {
    display: flex;
    justify-content: space-between;
    /* background:  #007044 top left url("../../assets/images/bg-noise-pattern-footer-green.png"); */
    color: #ffffff;
    margin-top: 2.5rem;
    background:  #007044;
  }
  .footer-left {
    padding-left: 11%;
  }
  .footer-right {
    padding-right: 11%;
  }
  
  footer h6 {
    font-size: 0.9rem !important;
  }
  footer a {
    color: #ffffff !important;
  }