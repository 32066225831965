.hero {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 3rem 20px 0;
}

.hero--right {
  width: 100%;
}

.hero--headline,
.hero--desc {
  max-width: 90%;
}

.global-splash-cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10px auto;
  max-width: 1200px;
}

.splash-card {
  max-width: 275px;
  background: #fafafa;
  margin-left: 15px;
  margin-right: 15px;
}

.global-splash-cards-wrapper .splash-card {
  max-width: 250px;
  background: #fafafa;
}

.global-splash-cards-wrapper .splash-card--action-area {
  transition: transform 3000 !important;
  display: flex !important;
  text-align: center !important;
  flex-direction: column !important;
}
.splash-card--action-area:hover {
  background: bottom left no-repeat
    url("../../assets/images/bg-splash-card.png");
}

.global-splash-cards-wrapper .splash-cards {
  display: flex;
  justify-content: center;
    max-width: 48%;
    margin: 20px 0;
    flex-wrap: wrap;
}
.global-splash-cards-wrapper > .splash-cards {
  border-right: 1px solid gray;
}

.global-splash-cards-wrapper > .splash-cards ~ .splash-cards {
   border: none;
}

div.global-splash-logo-region {
  display: flex;
  align-items: baseline;
  gap: 20px;
  margin-left: 7px;
}
div.global-splash-logo-region .griffith--logo {
  width: 140px;
  padding-right: 20px;
  border-right: 1px solid gray;
  padding-top: 5px;
}
div.global-splash-logo-region .flavoriq--logo {
  width: 145px;
}

sup {
  position: relative;
  font-size: 40%;
  line-height: 0;
  vertical-align: baseline;
  top: -1.2em;
  font-style: normal;
}

 div.global-intro-headline {
  width: 483px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
 div.global-intro-headline img {
  margin-left: 40%;
}
