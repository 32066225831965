
body {
    background-color: #fafafa;
}
.filter-managers {
   /* display: flex;
   // justify-content: space-between;
   // max-width: 80%;
   // margin: 0 auto;*/
}

.small-logo {
    max-width: 80px;
}

.filter h6 {
    color: #000000;
}