
body {
    background-color: #fafafa;
}
.stepper {
    margin-bottom: 50px;
}

.select-icon {
    width: 20px;
    margin-right: 15px;
}

#country {
    display: flex;
    align-items: center;
}

.brand-select div > div {
    display: flex;
}
