.hero {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 3rem 20px 0;
}
.splash-swoosh {
  margin-left: 20%;
}
.hero--right {
  width: 100%;
}

.hero--headline, .hero--desc {
  max-width: 90%;
}

.hero .hero--logo {
  width: 75%;
}

.splash-card {
  max-width: 270px;
  background: #fafafa;
}

.splash-card--action-area {
  transition: transform 3000 !important;
  display: flex !important;
  text-align: center !important;
  flex-direction: column !important;
}
.splash-card--action-area:hover {
  background: bottom left no-repeat
    url("../../assets/images/bg-splash-card.png");
}
.splash-cards {
  display: flex;
  justify-content: space-between;
  max-width: 63%;
  margin: 0 auto;
}
